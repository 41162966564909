<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="contact__banner">
        <div class="container contact__banner__content">
          <h2>Contacto</h2>
          <h4>How can we help?</h4>
        </div>
      </div>
      <br>
      <div class="container contact__info">
        <!-- <div class="contact__info__numbers">
          <h4>Contact Numbers</h4>
          <div class="contact__info__numbers__items">
            <div class="contact__info__numbers__items__single">
              <p class="single-title">
                Reservations
              </p>
              <p class="data">
                1-888-222-222
              </p>
            </div>
            <div class="contact__info__numbers__items__single">
              <p class="single-title">
                Owners
              </p>
              <p class="data">
                1-888-222-222
              </p>
            </div>
            <div class="contact__info__numbers__items__single">
              <p class="single-title">
                Travelers
              </p>
              <p class="data">
                1-888-222-222
              </p>
            </div>
            <div class="contact__info__numbers__items__single">
              <p class="single-title">
                Emergencies
              </p>
              <p class="data">
                1-888-222-222
              </p>
            </div>
          </div>
        </div> -->
        <div class="row contact__info__main">
          <div class="col-12 col-md-6 contact__info__main__address">
            <h4>Main Office Address</h4>
            <hr>
            <p>Ave. Centenario, Edificio Peninsula Center, Oficina 804, Costa del Este. Panama.</p>
            <p>Email: <a href="mailto:info@homebelike.com">info@homebelike.com</a></p>
            <!-- <p>Phone: <a href="tel:555555555">555-555-5555</a></p> -->
            <GmapMap :center="{ lat: 18.471208, lng: -69.9471542 }"
                     :zoom="16"
                     style="width: 100%; height: 360px"
                     class="embed-responsive-item map">
              <GmapMarker :position="google && new google.maps.LatLng(18.471208, -69.9471542)"
                          icon="/img/MarkerMap.png"
                          :clickable="true"
                          :draggable="false"
                          @click="center=m.position" />
            </GmapMap>
          </div>
          <div class="col-12 col-md-6 contact__info__main__form">
            <h4>Send us a message:</h4>
            <form @submit.prevent="contactForm">
              <div class="form-group has-icon">
                <i class="far fa-user" />
                <input v-model="contact.name"
                       v-validate="'required'"
                       name="name"
                       type="text"
                       class="form-control"
                       placeholder="First and Last Name">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('name')">{{ errors.first('name') }}</span>
              <div class="form-group has-icon">
                <i class="far fa-envelope" />
                <input v-model="contact.email"
                       v-validate="'required|email'"
                       name="email"
                       type="text"
                       class="form-control"
                       placeholder="Email Address">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('email')">{{ errors.first('email') }}</span>
              <div class="form-group has-icon">
                <i class="fas fa-mobile-alt" />
                <input v-model="contact.phone"
                       v-validate="'required'"
                       name="phone"
                       type="text"
                       class="form-control"
                       placeholder="Mobile or Phone Number">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('phone')">{{ errors.first('phone') }}</span>
              <div class="form-group has-icon">
                <textarea v-model="contact.message"
                          v-validate="'required'"
                          name="message"
                          type="text"
                          class="form-control"
                          rows="6"
                          placeholder="Message or question" />
              </div>
              <div class="alert alert-success"
                   role="alert"
                   v-if="formResponse">
                {{ formResponse }}
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('message')">{{ errors.first('message') }}</span>
              <button class="btn btn-primary btn-block btn-lg">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <newsletter />
    </main>
    <MyFooter />
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import Newsletter from '../components/Newsletter.vue';

export default {
  components: { Newsletter },
  name: 'Contact',
  data() {
    return {
      formResponse: null,
      contact: {
        name: null,
        email: null,
        phone: null,
        message: null,
      },
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Contact Us',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'Let us know how can we help you.' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'Contact Us | CapCana Rentals' },
      { itemprop: 'description', content: 'Let us know how can we help you.' },
      { itemprop: 'image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Contact Us | CapCana Rentals' },
      { name: 'twitter:description', content: 'Let us know how can we help you.' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Open Graph data
      { property: 'og:title', content: 'Contact Us | CapCana Rentals' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/contact' },
      { property: 'og:image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      { property: 'og:description', content: 'Let us know how can we help you.' },
    ]
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    contactForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('fullname', this.contact.name);
          formData.append('email', this.contact.email);
          formData.append('phone', this.contact.phone);
          formData.append('message', this.contact.message);
          this.$axios.post('/v1/contactcenter/form', formData).then((response) => {
            this.formResponse = response.data.message;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact__banner::before {
  background-image: url('~@/assets/images/contact-banner.jpg');
  z-index: -1;
}
</style>
